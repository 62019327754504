export function totalNumValidate(value, context) {
  let result = value;
  const valueStr = String(parseInt(value));
  const dicStr = String(value).split(".")[1]
    ? String(value).split(".")[1]
    : "00";
  if (valueStr.length > 5) {
    context.$toast("请您输入小于五位数的数字");
    result = valueStr.substr(0, 5) + "." + dicStr;
  } else {
    if (dicStr.length > 2) {
      context.$toast("最多支持两位小数");
      result = valueStr.substr(0, 5) + "." + dicStr.substr(0, 2);
    }
  }

  return result
}