<template>
  <div class="calcu-body buyness-calcu">
    <van-form class="form-bg">
      <!-- 选择城市 -->
      <van-field
        readonly
        clickable
        v-model="locationCity"
        label="选择城市"
        placeholder="点击选择城市"
        @focus="linkLocation"
        @click-right-icon="linkLocation"
        @click-left-icon="handleLocation"
      >
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <!-- 计算方式 -->
      <van-field
        name="radio"
        label="计算方式"
        class="cal-method"
      >
        <template #input>
          <van-radio-group 
            v-model="formData.methodType"
            direction="horizontal"
            @change="calcuMethodChange"
          >
            <van-radio name="1">房屋总价</van-radio>
            <van-radio name="2">贷款总额</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- 房屋总价 -->
      <van-field
        v-model="formData.houseTotal"
        type="number"
        name="房屋总价"
        label="房屋总价"
        autocomplete="off"
        placeholder="请输入房屋总价"
        v-if="formData.calcuMethod === '按房屋总价'"
        @focus="houseTotalFocus"
        @click="houseKeyboardShow = true"
      >
        <template #extra>
          <span class="suffix">万</span>
        </template>
      </van-field>
      <!-- 房屋总价的数字键盘 -->
      <van-number-keyboard
        :show="houseKeyboardShow"
        v-model="formData.houseTotal"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="houseKeyboardClose"
        @blur="houseKeyboardClose"
        style="z-index: 3000"
      />

      <!-- 首付比例 -->
      <van-field
        name="radio"
        label="首付比例"
        v-if="formData.calcuMethod === '按房屋总价'"
        @click-left-icon="handleownPayment"
        @click-right-icon="showDownPaymentPicker = true"
      >
        <template #input>
          <van-radio-group
            v-model="formData.isFirst"
            direction="horizontal"
            @change="isFirstChange"
          >
            <van-radio name="1">首套</van-radio>
            <van-radio name="2">二套</van-radio>
            <span
              v-if="formData.isFirst === '1' && !selectedPayment"
              class="down-payment-per"
              @click="showDownPaymentPicker = true"
              >{{ firstDefault }}</span
            >
            <span
              v-if="formData.isFirst === '2' && !selectedPayment"
              class="down-payment-per"
              @click="showDownPaymentPicker = true"
              >{{ secondDefault }}</span
            >
            <span
              v-if="formData.isFirst && selectedPayment"
              class="down-payment-per"
              @click="showDownPaymentPicker = true"
              >{{ formData.downPayment }}</span
            >
          </van-radio-group>
        </template>
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="首付比例" 
          :pickList="downPaymentList" 
          typeKey="downPayment" 
          v-if="showDownPaymentPicker" 
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
          v-fixed
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入百分比" v-model="formData.customDownPayment">
              <button class="customer-btn" @click="handleCustomDownPayment">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <!-- 货款总额 -->
      <van-field
        v-model="formData.total"
        type="number"
        name="贷款总额"
        label="贷款总额"
        autocomplete="off"
        placeholder="请输入贷款总额"
        @focus="totalFocus"
        @click="totalkeyboardShow = true"
        readonly
      >
        <template #extra>
          <span class="suffix">万</span>
        </template>
      </van-field>
      <!-- 货款总额-数字键盘 -->
      <van-number-keyboard
        :show="totalkeyboardShow"
        v-model="formData.total"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="totalKeyboardClose"
        @blur="totalKeyboardClose"
        style="z-index: 3000"
      />

      <!-- 贷款年限 -->
      <van-field
        readonly
        clickable
        v-model="formData.loanTerm"
        label="贷款年限"
        placeholder="点击选择贷款年限"
        @focus="showLoanTermPicker = true"
        @click-right-icon="showLoanTermPicker = true"
      >
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="贷款年限" 
          :pickList="loanTermList" 
          typeKey="loanTerm"
          v-if="showLoanTermPicker"
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入贷款年限" v-model="formData.customTerm">
              <button class="customer-btn" @click="handleCustomTerm">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>
      <!-- 利率方式 -->
      <van-field
        readonly
        clickable
        v-model="formData.interestRateMethod"
        label="利率方式"
        placeholder="点击选择利率方式"
        @focus="showInterestRatePicker = true"
        @click-right-icon="showInterestRatePicker = true"
      >
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          title="利率方式" 
          :pickList="interestRateMethodList" 
          typeKey="interestRate"
          v-if="showInterestRatePicker"
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        ></Picker>
      </keep-alive>
      <!-- 贷款利率 -->
      <van-field
        readonly
        clickable
        v-model="formData.rate"
        label="贷款利率"
        placeholder="点击选择利率方式"
        @focus="showRatePicker = true"
        @click-left-icon="handleRateHelp"
        @click-right-icon="showRatePicker = true"
      >
        <template #left-icon>
          <img
            src="../../assets/image/help.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
        <template #right-icon>
          <img
            src="../../assets/image/more.png"
            alt=""
            width="15"
            height="15"
          />
        </template>
      </van-field>
      <keep-alive>
        <Picker 
          :title="formData.interestRateMethod === '按LPR'? '本月LPR':'旧版基准利率'" 
          :pickList="formData.interestRateMethod === '按LPR'? rateList: rateBaseList" 
          typeKey="rate"
          v-if="showRatePicker"
          @pickerHandle="pickerHandle"
          @pickerClose="pickerClose"
        >
          <template #footer>
            <div class="picker-footer">
              <span>自定义输入</span>
              <input type="text" placeholder="请输入贷款利率" v-model="formData.customRate">
              <button class="customer-btn" @click="handleCustomRate">确定</button>
            </div>
          </template>
        </Picker>
      </keep-alive>

      <!-- 自定义贷款利率的数字键盘 -->
      <van-number-keyboard
        :show="customRatekeyboardShow"
        v-model="formData.customRate"
        theme="custom"
        extra-key="."
        close-button-text="完成"
        @close="customRateKeyboardClose"
        @blur="customRateKeyboardClose"
        style="z-index: 3000"
      />

      <div style="margin: 16px">
        <van-button class="submit-btn" @click="handleSubmit"
          >开始计算</van-button
        >
      </div>
    </van-form>

    <!-- 模态框 -->
    <van-dialog
      v-model="customModalShow"
      show-cancel-button
      confirm-button-color="#FFCC33"
      @confirm="modalConfirm"
      @cancel="modalCancel"
    >
      <template #default>
        <div class="custom-dialog-content">
          目前贷款年限最大{{ $store.state.location.cityId == 1 ? 25 : 30 }}年，您确定查看吗？
        </div>
      </template>
    </van-dialog>
  </div>
</template>

<script>
import $http from "../../utils/request";
import { totalNumValidate } from '../../validate/index';
import { mapState } from "vuex";
import Picker from "../../components/Picker";

export default {
  components: {
    Picker
  },
  data() {
    return {
      showMehodsPicker: false,
      methodsList: ["按房屋总价", "按贷款总额"],
      showLoanTermPicker: false,
      showInterestRatePicker: false,
      interestRateMethodList: ["按LPR", "旧版基准利率"],
      showRatePicker: false,
      showDownPaymentPicker: false,
      downPaymentList: [
        "20%",
        "25%",
        "30%",
        "35%",
        "40%",
        "45%",
        "50%",
        "60%",
        "70%",
        "80%",
      ],
      formData: {
        total: "0",
        calcuMethod: this.$route.query.cal || "按贷款总额",
        loanTerm: "25年",
        customTerm: "",
        interestRateMethod: "按LPR",
        rate: "",
        customRate: "",
        houseTotal: "0",
        isFirst: "1",
        methodType: "2",
        downPayment: "30%",
        customDownPayment: "",
      },
      customModalShow: false,
      selectedPayment: false,
      totalkeyboardShow: false,
      houseKeyboardShow: false,
      customDownPaymentKeyboardShow: false,
      customTermkeyboardShow: false,
      customRatekeyboardShow: false,
    };
  },
  watch: {
    // 监听后端给出的贷款利率，设置默认值
    rateList: {
      handler(newVal) {
        if (newVal && this.formData.interestRateMethod === "按LPR") {
          this.formData.rate = newVal[0].text;
        }
      },
      immediate: true,
    },
    rateBaseList: {
      handler(newVal) {
        if (newVal && this.formData.interestRateMethod === "旧版基准利率") {
          this.formData.rate = newVal[0].text;
        }
      },
    },
  },
  methods: {
    pickerClose(typeKey) {
      console.log('close typeKey---->', typeKey);
      switch(typeKey) {
        case 'downPayment':
          this.showDownPaymentPicker = false;
          break;
        case 'loanTerm':
          this.showLoanTermPicker = false;
          break;
        case 'interestRate':
          this.showInterestRatePicker = false;
          break;
        case 'rate':
          this.showRatePicker = false;
          break;
      }
    },
    pickerHandle(isShow, selected, typeKey) {
      console.log(isShow, selected, typeKey);
      switch(typeKey) {
        case 'downPayment':
          this.onDownPaymentComplete(selected);
          break;
        case 'loanTerm':
          this.onLoanTermComplete(selected);
          break;
        case 'interestRate':
          this.onInterestRateComplete(selected);
          break;
        case 'rate':
          this.onRateComplete(selected);
          break;
      }
      // this.pickerShow = isShow;
    },
    // 自定义贷款利率的数字键盘关闭
    customRateKeyboardClose() {
      this.customRatekeyboardShow = false;
    },
    // 自定义贷款年限的数字键盘关闭
    customTermKeyboardClose() {
      this.customTermkeyboardShow = false;
    },
    // 自定义首付比例的数字键盘关闭
    // customDownPaymentKeyboardClose() {
    //   this.customDownPaymentKeyboardShow = false;
    // },
    // 贷款总额的数字键盘关闭
    totalKeyboardClose() {
      this.totalkeyboardShow = false;
      this.totalValidate();
    },
    // 贷款总额的表单验证
    totalValidate() {
      this.formData.total = totalNumValidate(this.formData.total, this);
      console.log(totalNumValidate(this.formData.total, this));
    },
    // 房屋总价的数字键盘关闭
    houseKeyboardClose() {
      if (this.formData.houseTotal) {
        this.onHouseTotal(this.formData.houseTotal);
      }
      this.houseKeyboardShow = false;
    },
    totalFocus() {
      if (this.formData.total == 0) {
        this.formData.total = "";
      }
      document.activeElement.blur();
    },
    houseTotalFocus() {
      if (this.formData.houseTotal == 0) {
        this.formData.houseTotal = "";
      }
      document.activeElement.blur();
    },
    linkLocation() {
      // 点击选择城市
      this.$router.push("/location");
    },
    handleLocation() {
      // 点击选择城市前的问号
      this.$store.commit("showLocationModal", true);
    },
    handleSubmit() {
      // 开始计算的埋点
      window.gio('track', 'loanclick', {
        "module_var": "商业贷款",
        "buttonname_var": "开始计算",
        "userid_var": this.$store.state.userId
      });

      if (this.formData.calcuMethod === "按贷款总额") {
        if (!Number(this.formData.total)) {
          this.$toast("贷款总额不能为空");
          this.formData.total = '0';
          return;
        }
      } else if (this.formData.calcuMethod === "按房屋总价") {
        if (!Number(this.formData.houseTotal)) {
          this.$toast("房屋总价不能为空");
          this.formData.houseTotal = '0';
          return;
        } else if (!Number(this.formData.total)) {
          this.$toast("贷款总额不能为空");
          this.formData.total = '0';
          return;
        }
      }
      const data = {};

      data.com_loan_time = parseInt(this.formData.loanTerm); // 贷款年限
      data.com_loan_value = Number(this.formData.total); // 贷款总额
      if (this.formData.calcuMethod === "按房屋总价") {
        data.is_first = this.formData.isFirst === "1" ? 1 : 0;
        data.house_price = Number(this.formData.houseTotal);
        data.payment_percent =
          parseFloat(
            document.getElementsByClassName("down-payment-per")[0].innerText
          ) / 100;
      } else {
        // 为保证参数的完整性，页面上不存在的参数传空字符串（后端要求）
        data.is_first = "";
        data.house_price = "";
        data.payment_percent = "";
      }
      // 需要判断当前是否为自定义贷款利率，当前若为自定义贷款利率则直接取自定义贷款利率，否则按利率方式判断！
      if (this.formData.customRate) {
        data.com_loan_rate = Number(this.formData.rate) / 100;
      } else {
        if (this.formData.interestRateMethod === "按LPR") {
          data.com_loan_rate =
            Number(
              this.rateList.find((item) => item.text === this.formData.rate)
                .value
            ) / 100; // 贷款利率
        } else {
          data.com_loan_rate =
            Number(
              this.rateBaseList.find((item) => item.text === this.formData.rate)
                .value
            ) / 100; // 贷款利率
        }
      }

      // 为保证参数的完整性，页面上不存在的参数传空字符串（后端要求）
      data.fund_loan_time = "";
      data.fund_loan_value = "";
      data.fund_loan_rate = "";

      // TODO 表单提交
      $http({
        url: "/appapi/normals/1/v1/loandetails",
        method: "post",
        data,
      })
        .then((res) => {
          if (res.msg == "OK") {
            // 存入store中
            this.$store.dispatch("setCalcuRes", res.data);
            // 在localStorage中做本地持久化
            localStorage.setItem("calcuRes", JSON.stringify(res.data));
            this.$router.push(
              "/CommercialIoanDetailed?type=busness&cal=" +
                this.formData.calcuMethod
            );
          }
        })
        .catch((err) => {
          console.log("err--->", err);
        });
    },
    // 贷款年限弹出框的完成
    onLoanTermComplete(value) {
      // 点击完成之后只对选择项有效
      this.formData.loanTerm = value;
      if (this.formData.interestRateMethod === "按LPR") {
        if (parseInt(this.formData.loanTerm) > 1) {
          this.formData.rate = this.com_rate_1["1"][0].text;
        } else {
          this.formData.rate = this.com_rate["1"][0].text;
        }
      }
      // 清空自定义填写项
      this.formData.customTerm = "";
      this.showLoanTermPicker = false;
    },
    onInterestRateComplete(value) {
      this.formData.interestRateMethod = value;
      if (this.formData.interestRateMethod === "按LPR") {
        this.formData.rate = this.rateList[0].text;
      } else {
        this.formData.rate = this.rateBaseList[0].text;
      }
      this.showInterestRatePicker = false;
    },
    handleCustomTerm(event) {
      // 自定义贷款年限
      let LOANTERM = this.$store.state.location.cityId == 1 ? 25 : 30;
      if (this.formData.customTerm <= LOANTERM) {
        this.showLoanTermPicker = false;
        this.formData.loanTerm = `${this.formData.customTerm}年`;
      } else {
        // 贷款年限大于30年小于等于60年，弹出提示框
        if (this.formData.customTerm > LOANTERM && this.formData.customTerm <= 60) {
          this.customModalShow = true;
        } else {
          this.$toast("自定义最大输入年限为60年，请重新输入！");
        }
      }
    },
    modalConfirm() {
      if (this.formData.interestRateMethod === "按LPR") {
        if (parseInt(this.formData.loanTerm) > 1) {
          this.formData.rate = this.com_rate_1["1"][0].text;
        } else {
          this.formData.rate = this.com_rate["1"][0].text;
        }
      }
      // TODO 模态框确定
      this.customModalShow = false;
      this.showLoanTermPicker = false;
      this.formData.loanTerm = `${this.formData.customTerm}年`;
    },
    modalCancel() {
      // TODO 模态框取消
      this.formData.customTerm = "";
    },
    onRateComplete(item) {
      this.formData.rate = item.text;
      this.showRatePicker = false;
    },
    handleRateHelp() {
      this.$store.commit("showRateModal", true);
    },
    handleownPayment() {
      this.$store.commit("showHouseModal", true);
    },
    handleCustomRate() {
      const LPRreg = /(^[0-9]{1,2}$)|(^[0-9]{1,2}[\.]{1}[0-9]{1,2}$)/;
      const isLPRExit =
        LPRreg.test(this.formData.customRate) &&
        Number(this.formData.customRate) > 0;

      if (this.formData.customRate) {
        if (isLPRExit) {
          // 通过验证
          this.formData.rate = this.formData.customRate;
          this.showRatePicker = false;
          this.customRatekeyboardShow = false;
        } else {
          this.$toast("自定义输入范围为0.01%-99.99，支持小数点后两位！");
        }
      } else {
        this.$toast("自定义贷款利率不能为空");
      }
    },
    onDownPaymentComplete(data) {
      // 首付比例
      this.formData.downPayment = data;
      // 改变首付比例百分比的显示状态
      this.selectedPayment = true;
      // 计算首付
      this.formData.total =
        ((Number(this.formData.houseTotal) *
          (100 - parseInt(this.formData.downPayment))) /
        100).toFixed(2);
      this.showDownPaymentPicker = false;
    },
    handleCustomDownPayment() {
      // 自定义首付比例
      if (Number(this.formData.customDownPayment)) {
        if (
          Number(this.formData.customDownPayment) > 100 ||
          Number(this.formData.customDownPayment) < 10
        ) {
          this.$toast("请输入10-100之间的数字");
        } else {
          this.formData.customDownPayment =
            this.formData.customDownPayment + "%";
          this.formData.downPayment = this.formData.customDownPayment;
          // 改变首付比例百分比的显示状态
          this.selectedPayment = true;
          // 计算首付
          this.formData.total =
            ((Number(this.formData.houseTotal) *
              (100 - parseInt(this.formData.downPayment))) /
            100).toFixed(2);
          this.showDownPaymentPicker = false;
        }
      } else {
        this.$toast("自定义首付比例不能为空！");
      }
    },
    onHouseTotal(value) {
      let defaultPercent = "";
      // 房屋总价的输入监听
      if (Number(value)) {
        const valueStr = String(parseInt(value));
        const dicStr = String(value).split(".")[1]
          ? String(value).split(".")[1]
          : "00";
        if (valueStr.length > 5) {
          this.$toast("请您输入小于五位数的数字");
          this.formData.houseTotal = valueStr.substr(0, 5) + "." + dicStr;
        } else {
          if (dicStr.length > 2) {
            this.$toast("最多支持两位小数");
            this.formData.houseTotal =
              valueStr.substr(0, 5) + "." + dicStr.substr(0, 2);
          }
        }

        // 根据首付比例来定贷款总额
        if (this.selectedPayment) {
          defaultPercent = this.formData.downPayment;
        } else {
          if (this.formData.isFirst === "1") {
            defaultPercent = this.firstDefault;
          } else {
            defaultPercent = this.secondDefault;
          }
        }

        this.formData.total =
          (Number(this.formData.houseTotal) *
            (100 - parseInt(defaultPercent))) /
          100;
        this.formData.total = Number(this.formData.total).toFixed(2);
      } else {
        this.formData.total = 0;
      }
    },
    calcuMethodChange(name) {
      if(name === "1") {
        this.formData.calcuMethod = '按房屋总价';
      }else if(name === "2"){
        this.formData.calcuMethod = '按贷款总额';
      }
    },
    isFirstChange(name) {
      this.selectedPayment = false;
      if (name === "1") {
        this.formData.total =
          ((Number(this.formData.houseTotal) *
            (100 - parseInt(this.firstDefault))) /
          100).toFixed(2);
      } else if (name === "2") {
        this.formData.total =
          ((Number(this.formData.houseTotal) *
            (100 - parseInt(this.secondDefault))) /
          100).toFixed(2);
      }
    },
  },
  computed: {
    defaultCal() {
      if (this.$route.query.cal) {
        if (this.$route.query.cal === "按贷款总额") {
          return 1;
        } else if (this.$route.query.cal === "按房屋总价") {
          return 0;
        }
      } else {
        return 1;
      }
    },
    firstDefault() {
      return this.$store.state.downPayment.firstDefault;
    },
    secondDefault() {
      return this.$store.state.downPayment.secondDefault;
    },
    locationCity() {
      return this.$store.state.location.cityName;
    },
    ...mapState({
      com_rate: (state) => {
        return state.com_rate;
      },
      com_rate_1: (state) => state.com_rate_1,
    }),
    rateList() {
      return parseInt(this.formData.loanTerm) > 1
        ? this.com_rate_1["1"]
        : this.com_rate["1"];
    },
    rateBaseList() {
      return parseInt(this.formData.loanTerm) > 1
        ? this.com_rate_1["2"]
        : this.com_rate["2"];
    },
    loanTermList() {
      return this.$store.state.loanTime;
    }
  },
};
</script>

<style scoped>
.calcu-body {
  width: 686px;
  margin: 24px auto;
  border-radius: 16px;
  overflow: hidden;
}
.form-bg {
  background-color: #ffffff;
  padding-bottom: 25px;
}
.submit-btn {
  width: 622px;
  height: 96px;
  border-radius: 16px;
  line-height: 96px;
  background-color: #ffcc33;
  border: none;
  font-size: 28px;
  color: #333333;
  font-weight: 600;
}
.calcu-body /deep/ .van-cell {
  padding-top: 45px;
  height: 129px;
  box-sizing: border-box;
}
.calcu-body /deep/ .van-cell::after {
  border: 1px solid #F5F5F5;
}
.calcu-body /deep/ .van-field__left-icon {
  position: absolute;
  top: 45px;
  left: 165px;
}
.calcu-body /deep/ .van-cell__title {
  color: #292B33;
  font-weight: bold;
  font-size: 32px;
}
.calcu-body /deep/ .van-field__body input,
.suffix {
  font-size: 32px;
  color: #333;
}
.cal-method /deep/ .van-radio__label {
  margin-left: 0;
}
.calcu-body /deep/ .van-radio-group .van-radio:last-child {
  margin-right: 0;
}
.calcu-body /deep/ .van-radio__icon .van-icon {
  width: 1em;
  height: 1em;
  line-height: 1;
}
.popup-title {
  width: 670px;
  height: 112px;
  border-bottom: 2px solid #eeeeee;
  line-height: 112px;
  font-size: 32px;
  color: #333;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.popup-title .complete {
  color: #ffcc33;
}
.custom-term {
  height: 112px;
  width: 670px;
  margin: 0 auto;
  border-top: 2px solid #eeeeee;
}
.customer-btn {
  color: #333;
  border: none;
  outline: none;
}
.custom-dialog-content {
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 25px 48px 25px;
  padding-top: 80px;
  width: 568px;
  height: 218px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  line-height: 45px;
  letter-spacing: 1px;
}
.down-payment-per {
  display: inline-block;
}
.margin-b-244 {
  margin-bottom: 488px;
}
</style>